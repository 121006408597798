/* eslint-disable react/prop-types */
import React from "react";
import SwiperWrapper from "../../../components/SwiperWrapper";
import { SwiperSlide } from "swiper/react";
import FastImage from "../../../../globals/elements/fastimg";
import { Autoplay } from "swiper/modules";
import { t } from "i18next";
import { useState } from "react";
// Styling Import
import styles from "./Slider.module.css";
import "./dropdown.css";
import { useParams } from "react-router-dom";

const HomeSlider = ({ slides }) => {
  const { lang } = useParams();
  const [to, setTo] = useState("");
  const [from, setFrom] = useState("");
  const [clicked, setClicked] = useState(false);

  const handleFromChange = (eventKey) => {
    setFrom(eventKey);
  };

  const handleToChange = (eventKey) => {
    setTo(eventKey);
  };

  const handleToggleClick = () => {
    setClicked(!clicked);
  };

  const displaySliders = () => {
    return slides?.map((slide) => {
      return (
        <>
          <SwiperSlide key={slide.id} className={styles.swiperSlide}>
            <div className={`${styles.container}`}>
              <div
                className={`${styles.imageContainer} d-flex align-items-top  justify-content-start flex-column`}
              >
                <FastImage
                  proImage={slide.image}
                  alt={slide.title}
                  className={styles.image}
                />
              </div>
            </div>
          </SwiperSlide>
        </>
      );
    });
  };

  return (
    <SwiperWrapper
      slidesPerView={1}
      loop
      autoplay={true}
      pagination={{
        clickable: true,
      }}
      style={{ height: "550px" }} // Adjusted height
      modules={[Autoplay]}
      className={styles.swiper}
    >
      {/* Include your Swiper slides */}
      {displaySliders()}
      {/* Include another component */}
    </SwiperWrapper>
  );
};

export default HomeSlider;
